import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84')
];

export const server_loads = [0,13,2,6];

export const dictionary = {
		"/(non-app)/(landing-pages)": [47,[13,14]],
		"/_test": [~83],
		"/admin/dl-company-logos": [~84],
		"/(app)/alerts": [~23,[2],[3]],
		"/(auth)/auth-callback": [40,[12]],
		"/(auth)/auth-verify-session-token": [41,[12]],
		"/(redirects)/auth": [75],
		"/(app)/backoffice": [~24,[2,6],[3]],
		"/(app)/backoffice/emails": [25,[2,6],[3]],
		"/(app)/backoffice/manage/articles": [~26,[2,6],[3]],
		"/(app)/backoffice/manage/changelog": [~27,[2,6],[3]],
		"/(app)/backoffice/manage/publishers": [~28,[2,6],[3]],
		"/(app)/backoffice/manage/reports": [~29,[2,6],[3]],
		"/(non-app)/blog": [~62,[13,16]],
		"/(non-app)/blog/(articles)/5-common-investing-mistakes": [63,[13,16,17]],
		"/(non-app)/blog/(articles)/crypto-trading-101": [64,[13,16,17]],
		"/(non-app)/blog/(articles)/from-novice-to-pro-investment-foundation": [65,[13,16,17]],
		"/(non-app)/blog/(articles)/fundamental-v-technical-analysis-for-blue-chip-stocks": [66,[13,16,17]],
		"/(non-app)/blog/(articles)/fundamental-v-technical-analysis-for-meme-stocks": [67,[13,16,17]],
		"/(non-app)/blog/(articles)/impact-of-breaking-news-on-stock-prices": [68,[13,16,17]],
		"/(non-app)/blog/(articles)/real-time-market-data-importance": [69,[13,16,17]],
		"/(non-app)/blog/(articles)/social-investing": [70,[13,16,17]],
		"/(non-app)/blog/(articles)/top-10-meme-stock-indicators": [71,[13,16,17]],
		"/(non-app)/blog/(articles)/v2": [72,[13,16,17]],
		"/(app)/(feeds)/(meta)/bookmarks": [18,[2,4],[3]],
		"/(non-app)/changelog": [~73,[13]],
		"/(app)/charts/[[symbol]]": [~30,[2,7],[3]],
		"/(non-app)/(subscriptions)/checkout-cancel": [58,[13]],
		"/(non-app)/(subscriptions)/checkout-complete": [~59,[13]],
		"/(non-app)/(subscriptions)/checkout-success": [60,[13]],
		"/(non-app)/(subscriptions)/checkout": [~57,[13]],
		"/(non-app)/(legal)/cookies": [54,[13,15]],
		"/(auth)/extension-callback": [42,[12]],
		"/(redirects)/extension": [~76],
		"/(redirects)/extension/install": [~77],
		"/(non-app)/(landing-pages)/faq": [~48,[13,14]],
		"/(non-app)/(landing-pages)/features": [~49,[13,14]],
		"/(non-app)/(landing-pages)/for-beginners": [50,[13,14]],
		"/(non-app)/(landing-pages)/for-crypto-traders": [51,[13,14]],
		"/(non-app)/(landing-pages)/for-day-traders": [52,[13,14]],
		"/(non-app)/(landing-pages)/for-value-investors": [53,[13,14]],
		"/(app)/home": [31,[2],[3]],
		"/(redirects)/install": [~78],
		"/(redirects)/item/[itemType]/[id]": [~79],
		"/(app)/i/[id]": [~32,[2,8],[3]],
		"/(redirects)/launch": [~80],
		"/(app)/markets": [33,[2,9],[3]],
		"/(app)/(feeds)/(std)/news": [21,[2,5],[3]],
		"/(app)/notifications": [~34,[2],[3]],
		"/(redirects)/onboarding": [~81],
		"/(auth)/onboard": [~43,[12]],
		"/(app)/(feeds)/(std)/posts": [22,[2,5],[3]],
		"/(redirects)/pricing": [~82],
		"/(non-app)/(legal)/privacy": [55,[13,15]],
		"/(app)/profile": [~35,[2],[3]],
		"/(app)/profile/[username]": [~36,[2],[3]],
		"/(app)/(feeds)/(meta)/publisher/[publisherUsername]": [19,[2],[3]],
		"/(app)/screener": [37,[2],[3]],
		"/(app)/search/[[searchQuery]]": [38,[2,10],[3]],
		"/(auth)/signin": [44,[12]],
		"/(auth)/signout": [45,[12]],
		"/(auth)/signup": [46,[12]],
		"/(non-app)/(subscriptions)/subscribe": [~61,[13]],
		"/(non-app)/symbol/[symbol]": [~74,[13]],
		"/(app)/(feeds)/(meta)/tag/[tagValue]": [~20,[2],[3]],
		"/(non-app)/(legal)/tos": [56,[13,15]],
		"/(app)/watchlist": [39,[2,11],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';